<template>
	<div id="ag-grid-demo">
		<vx-card id="grid-loader" class="vs-con-loading__container">
			<ag-grid-vue
				:gridOptions="gridOptions"
				class="ag-theme-material w-100 mb-4 ag-grid-table"
				:columnDefs="columnDefs"
				:defaultColDef="defaultColDef"
				:rowData="tableData"
				rowSelection="multiple"
				:enableRangeSelection="true"
				:suppressCopyRowsToClipboard="true"
				colResizeDefault="shift"
				:suppressDragLeaveHidesColumns="true"
				:animateRows="false"
				:floatingFilter="true"
				:pagination="true"
				:paginationPageSize="paginationPageSize"
				:suppressPaginationPanel="true"
				:suppressRowClickSelection="true"
				:rowClassRules="rowClassRules"
				:statusBar="statusBar"
				:sideBar="sideBar"
				:rowHeight="38"
				:getContextMenuItems="getContextMenuItems"
				@filter-changed="onFilterChanged"
				@column-resized="onColumnResized">
			</ag-grid-vue>
			<div class="vx-row">
				<div class="vx-col w-full">
					<vs-pagination
					:total="totalPages"
					:max="maxPageNumbers"
					v-model="currentPage" />
				</div>
			</div>
		</vx-card>

		<vs-prompt id="shortname-prompt" vs-title="Edit Short Name" :vs-is-valid="shortName['input'] != ''" @vs-cancel="cancelShortName" @vs-accept="saveShortName" :vs-active.sync="shortName['prompt']">
			<div class="con-exemple-prompt">
				<vs-input class="w-full" v-model="shortName['input']"/>
			</div>
		</vs-prompt>

		<vs-prompt id="mailto-prompt" vs-title="Edit Mailto Emails" :vs-is-valid="mailTo['emails'].length != 0" @vs-cancel="cancelMailTo" @vs-accept="saveMailTo" :vs-active.sync="mailTo['prompt']">
			<div class="con-exemple-prompt">
				<div class="mb-2">
					<vs-input class="w-full" name="email" placeholder="Add Email (Enter to Add)" v-model="mailTo['input']" @keyup.enter="addEmail()" v-validate="'email'"/>
					<span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
				</div>
				<div class="flex flex-wrap w-100">
					<template v-if="mailTo['emails'].length">
						<vs-chip @click="removeEmail(item)" v-for="(item,index) in mailTo['emails']" :key="index" closable>{{ item }}</vs-chip>
					</template>
					<template v-else>
						<vs-chip>No emails selected</vs-chip>
					</template>
				</div>
			</div>
		</vs-prompt>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
		data() {
			return {
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filter: false,
					filterParams: {
						suppressAndOrCondition: true,
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				columnDefs: [	
				{
					headerName: 'Registrar',
					field: 'organization',
					filter: "agTextColumnFilter",
					minWidth: 275,
					pinned: 'left',
				},
				{
					headerName: 'Registrar ID',
					field: 'registrar_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Short Name',
					field: 'short_name',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'URL',
					field: 'href_url',
					filter: "agTextColumnFilter",
					minWidth: 335,
				},
				{
					headerName: 'IANA ID',
					field: 'iana_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
					hide: true,
				},
				{
					headerName: 'Country',
					field: 'country_code',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Phone',
					field: 'phone_voice',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Primary Email',
					field: 'primary_email',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Security Email',
					field: 'security_email',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Mailto',
					field: 'mail_to',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				{
					headerName: 'Updated At',
					field: 'updated_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				],
				tableData: null,
				shortName: {
					id: null,
					input: '',
					prompt: false
				},
				mailTo: {
					id: null,
					input: '',
					emails: [],
					prompt: false
				},
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
		},
		methods: {
			showDivLoading(){
				this.$vs.loading({
					container: '#grid-loader',
					scale: 0.45
				});
			},
			hideDivLoading(){
				this.$vs.loading.close("#grid-loader > .con-vs-loading");
			},
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			onFilterChanged() {
				// Set Listing Filtered Count
				this.$store.dispatch("setListingFilteredCount", this.gridApi.getDisplayedRowCount());
			},
			cancelShortName() {
				this.shortName['id'] = null;
				this.shortName['input'] = '';
			},
			saveShortNamePrompt() {
				this.shortName['prompt'] = true;
			},
			saveShortName() {
				let self = this;
				// Show loader
				this.showDivLoading();
				// Post Axios Call
				this.$axiosSecure.post('/edit-registrar-short-name', {
					id: self.shortName['id'],
					name: self.shortName['input'],
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then(() => {
					// Confimation notification
					self.$vs.notify({
						color: 'success',
						title: 'Short Name update success',
						text: 'The selected Short Name has been updated'
					});
					
					// Table Data AXIOS Call
					this.populateTableData('loader');
				})
				.catch((error) => {
					// Hide loader
					this.hideDivLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			cancelMailTo() {
				this.mailTo['id'] = null;
				this.mailTo['input'] = '';
				this.mailTo['emails'] = [];
			},
			saveMailToPrompt() {
				this.mailTo['prompt'] = true;
			},
			saveMailTo() {
				let self = this;
				// Show loader
				this.showDivLoading();
				// Post Axios Call
				this.$axiosSecure.post('/edit-registrar-mailto', {
					id: self.mailTo['id'],
					emails: JSON.stringify(self.mailTo['emails']),
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then(() => {
					// Confimation notification
					self.$vs.notify({
						color: 'success',
						title: 'Mailto update success',
						text: 'The selected Mailto has been updated'
					});
					
					// Table Data AXIOS Call
					this.populateTableData('loader');
				})
				.catch((error) => {
					// Hide loader
					this.hideDivLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			addEmail() {
				this.mailTo['emails'].push(this.mailTo['input']);
				this.mailTo['input'] = '';
			},
			removeEmail(item) {
				this.mailTo['emails'].splice(this.mailTo['emails'].indexOf(item), 1);
			},
			populateTableData(loader) {
				let self = this;
				
				// Get Blacklisted JSON
				this.$axiosSecure.get('/get-registrars-json')
				.then((response) => {
					this.tableData = response.data;

					// Set Listing Count
					this.$store.dispatch("setListingTotalCount", response.data.length);
					// Set Listing Filtered Count
					this.$store.dispatch("setListingFilteredCount", response.data.length);
				
					if(loader == 'loader') {
						// Hide loader
						this.hideDivLoading();
					}
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			getContextMenuItems(params) {
				return [
					{
						name: "Edit Node",
						subMenu: [
							{
								name: "Short Name",
								action: () => {
									this.shortName['id'] = params['node']['data']['id'];
									this.shortName['input'] = params['node']['data']['short_name'] != null ? params['node']['data']['short_name'] : '';
									this.saveShortNamePrompt();
								},
							},
							{
								name: "Mail To",
								action: () => {
									this.mailTo['id'] = params['node']['data']['id'];
									this.mailTo['input'] = '';
									this.mailTo['emails'] = params['node']['data']['mail_to'] != null ? JSON.parse(params['node']['data']['mail_to']) : [];
									this.saveMailToPrompt();
								},
							},
						]
					},
					'separator',
					'copy',
					'copyWithHeaders',
					'paste',
					'separator',
					'export',
				];
			}
		},
		beforeMount() {
			// Table Data AXIOS Call
			this.populateTableData();

			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
			
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Reset pinned columns on mobile
			this.toggleColumnPinned(this.windowWidth);

			// Page Refresh Event
			this.$root.$on('refreshPage', () => {
				// Reset filters
				this.gridApi.setFilterModel(null);
				// Reset selection
				this.gridApi.deselectAll();
				// Reset sort
				this.gridApi.setSortModel([
					{
					colId: "created_at",
					sort: "desc"
					}
				]);
				// Table Data AXIOS Call
				this.populateTableData();
			});

			// Page Download Event
			this.$root.$on('downloadPage', () => {
				// AgGrid CSV Export
				this.gridApi.exportDataAsCsv()
			});

		},
		destroyed() {
			// Clear Listing Count
			this.$store.dispatch("clearListingCount");
			
			// Unbind Button Event Listeners
			this.$root.$off('refreshPage');
			this.$root.$off('downloadPage');
		}
	}

</script>